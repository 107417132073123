//
// Navigation
//

import 'bootstrap/js/src/button';
import 'bootstrap/js/src/collapse';
import ScrollSpy from 'bootstrap/js/src/scrollspy';

// Navbar shrink function
const navbarShrink = function navbarShrink() {
  const navbarCollapsible = document.body.querySelector('#main-nav');
  if (!navbarCollapsible) {
    return;
  }
  if (window.scrollY === 0) {
    navbarCollapsible.classList.remove('navbar-shrink');
  }
  else {
    navbarCollapsible.classList.add('navbar-shrink');
  }
};

// Shrink the navbar
navbarShrink();

// Shrink the navbar when page is scrolled
document.addEventListener('scroll', navbarShrink);

// Activate Bootstrap scrollspy on the main nav element
const mainNav = document.body.querySelector('#main-nav');
if (mainNav) {
  // eslint-disable-next-line no-new
  new ScrollSpy(document.body, {
    target: '#main-nav',
    offset: 74,
  });
}

// Collapse responsive navbar when toggler is visible
const navbar = document.body.querySelector('#navbarResponsive');
const navbarToggler = document.body.querySelector('.navbar-toggler');

const responsiveNavItems = [].slice.call(
  document.querySelectorAll('#navbarResponsive .nav-link'),
);
const navBrand = [].slice.call(
  document.querySelectorAll('.navbar-brand'),
);

[...navBrand, ...responsiveNavItems].map(function clickToggler(responsiveNavItem) {
  return responsiveNavItem.addEventListener('click', () => {
    if (window.getComputedStyle(navbarToggler).display !== 'none' && navbar.classList.contains('show')) {
      navbarToggler.click();
    }
  });
});

// Cheat to allow external links with Bootstrap ScrollSpy
const otherNavItems = [].slice.call(
  document.querySelectorAll('#navbarResponsive .nav-link.scroll-spy-cheat'),
);
otherNavItems.map(function allowOtherNavItems(otherNavItem) {
  return otherNavItem.addEventListener('click', () => {
    window.location.href = otherNavItem.getAttribute('href');
  });
});
